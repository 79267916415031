import React from 'react';

import { Spinner } from '../Spinner';

export const LoadingButton = ({
  children,
  className,
  loading = false,
  type = 'button',
  ...attrs
}) => (
  <button className={className} disabled={!!loading} type={type} {...attrs}>
    {children}

    {loading && <Spinner className="spinner-border spinner-border-sm ml-3" />}
  </button>
);
