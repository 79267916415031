import React from 'react';

type SpinnerProps = {
  children?: React.ReactElement;
  className?: string;
}

export const Spinner = ({ children, className = 'spinner-border' }: SpinnerProps ) => (
  <div className={className} role="status" aria-hidden="true">
    <span className="sr-only">{children}</span>
  </div>
);
